import React from 'react'
import tw, { styled } from 'twin.macro'

const Main = styled.main`
  ${({ sidebar }) => (sidebar ? tw`w-full lg:w-8/12 sm:order-last lg:order-first` : tw`w-full`)}
  ${({ reverse, sidebar }) => (sidebar && reverse ? tw`lg:pl-10` : tw`lg:pr-2`)}
`

const Sidebar = styled.aside`
  ${tw`w-full lg:w-4/12`}
  ${({ reverse }) => (reverse ? tw`lg:pr-10` : tw`lg:pl-10`)}

  > div {
    ${tw`mb-8`}
  }

  .twitter-timeline {
    > div > div {
      ${tw`max-w-full`}
    }
  }
`

export const PageLayout = ({ children, sidebar = null, order = null }) => {
  const reverse=false
  return (
    <section className="w-full flex relative">
      <div className="block relative container max-w-7xl content-center justify-between mx-auto px-6 md:px-10">
        <div className="flex flex-col lg:flex-row">
          <Main sidebar={sidebar} reverse={reverse} order={order} className="layout_main">{children}</Main>
          {sidebar ? <Sidebar className="layout_sidebar">{sidebar}</Sidebar> : null}
        </div>
      </div>
    </section>
  )
}

export const SearchLayout = ({ search, results }) => {
  const reverse=false
  return (
    <section className="w-full flex relative">
      <div className="block relative container max-w-7xl content-center justify-between mx-auto px-6 md:px-10 py-10 md:py-20">
        <div className="flex flex-col lg:flex-row">
          <Sidebar reverse>{search}</Sidebar>
          <Main sidebar reverse={reverse}>
            {results}
          </Main>
        </div>
      </div>
    </section>
  )
}

export const AgendaLayout = ({ search, results, sidebar = null }) => {
  return (
    <div className="block relative">
      <div className="flex flex-col lg:flex-row w-full">
        <aside className="w-full md:w-full lg:w-4/12 lg:pr-8">{search}</aside>
        {sidebar ? <aside className="w-full md:hidden mb-6">{sidebar}</aside> : null}
        <div className="flex flex-col md:flex-row w-full">
          <div className="w-full md:w-8/12 md:pr-6 lg:px-6" css={[!sidebar && tw`md:w-10/12`]}>
            {results}
          </div>
          {sidebar ? <aside className="hidden md:flex md:w-4/12 lg:pl-4">{sidebar}</aside> : null}
        </div>
      </div>
    </div>
  )
}

export default PageLayout
